/* Center everything */
.valentines-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    text-align: center;
    font-family: "Arial", sans-serif;
    /* background: radial-gradient(circle, #ff5e62, #ff9966); */
    background: url("/images/tangled.jpg") no-repeat center center fixed;
    background-size: cover;
    transform: scale(1.5);
    z-index: -999
}

/* Slot machine container */
.slot-machine {
    position: relative;
    background: #b30000;
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 10px solid gold;
    width: 400px;
}

/* Slot container */
.slots {
    display: flex;
    justify-content: center;
    background: white;
    padding: 15px;
    border-radius: 15px;
    box-shadow: inset 0px -5px 10px rgba(0, 0, 0, 0.3);
    overflow: hidden;
}

/* Individual slot */
.slot {
    width: 120px;
    height: 150px;
    margin: 0 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid gold;
    border-radius: 10px;
    background: #fff;
    overflow: hidden;
    position: relative;
}

/* Ensure all slot images are the same size */
.slot img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

/* Spinning effect: images move upwards */
.spinning img {
    animation: verticalSpin 0.02s linear infinite;
}

@keyframes verticalSpin {
    0% { transform: translateY(100%); }
    100% { transform: translateY(-100%); }
}

/* Restore Lever Visibility */
.lever-container {
    position: absolute;
    right: -60px;
    top: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    transform-origin: bottom center;
    transition: transform 0.3s ease-in-out;
    z-index: 10; /* Ensure the lever is always visible */
}

/* Lever stick (brown) */
.lever {
    width: 15px;
    height: 100px;
    background: brown;
    border-radius: 10px;
    position: relative;
}

/* Lever ball (red, now in front of the lever) */
.lever-ball {
    width: 30px;
    height: 30px;
    background: red;
    border-radius: 50%;
    box-shadow: 2px 2px 5px black;
    position: absolute;
    top: -15px; /* Moves ball to the top */
    left: 50%;
    transform: translateX(-50%);
    z-index: 11; /* Ensures it's in front of the lever */
}

/* Lever swings 45 degrees outwards */
.pulled {
    transform: rotate(45deg);
}

/* Winner pop-up (now slightly bigger) */
.winner-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 300px; /* Increased from 250px to 300px */
    height: 300px; /* Keep it square */
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border: 5px solid gold;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
    animation: popUp 1s ease-in-out forwards;
}

/* Winner image inside the pop-up */
.winner-popup img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

/* Pop-up enlarging animation */
@keyframes popUp {
    0% { transform: translate(-50%, -50%) scale(0); }
    100% { transform: translate(-50%, -50%) scale(1); }
}

/* Restore the success/failure message */
.message {
    margin-top: 20px;
    font-size: 25px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px black;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    display: inline-block;
}

/* Fix the message position */
.message {
    position: absolute;
    bottom: 25%; 
    /* left: 50%; */
    /* transform: translateX(-50%); */
    font-size: 25px;
    font-weight: bold;
    color: white;
    text-shadow: 2px 2px 4px black;
    padding: 10px 20px;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 10px;
    display: inline-block;
}

@media (min-width: 1440px) {
    .slot-machine {
        max-width: 600px;
        padding: 30px;
    }
    .slot {
        width: 140px;
        height: 170px;
    }
}

/* 🔹 Laptops/Tablets (1024px - 1439px) */
@media (max-width: 1439px) {
    .slot-machine {
        max-width: 500px;
        padding: 25px;
    }
    .slot {
        width: 120px;
        height: 150px;
    }
}

/* 🔹 Tablets (768px - 1023px) */
@media (max-width: 1023px) {
    .slot-machine {
        max-width: 400px;
        padding: 20px;
    }
    .slot {
        width: 100px;
        height: 130px;
    }
    .winner-popup {
        width: 260px;
        height: 260px;
    }
    .message {
        font-size: 18px;
    }
}

/* 🔹 Small Tablets & Large Phones (480px - 767px) */
@media (max-width: 767px) {
    .slot-machine {
        max-width: 350px;
        padding: 15px;
    }
    .slot {
        width: 90px;
        height: 120px;
    }
    .winner-popup {
        width: 220px;
        height: 220px;
    }
    .message {
        font-size: 16px;
    }
}

/* 🔹 Small Phones (Below 480px) */
@media (max-width: 480px) {
    .slot-machine {
        max-width: 95%;
        padding: 10px;
    }
    .slot {
        width: 80px;
        height: 100px;
    }
    .winner-popup {
        width: 200px;
        height: 200px;
    }
    .message {
        font-size: 14px;
        bottom: 10px;
    }
}

/* Ensure Confetti Covers the Whole Screen */
.confetti-container {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    pointer-events: none;
    overflow: hidden;
    z-index: 9999; /* Ensures Confetti is on Top */
}

/* Individual Confetti Pieces */
.confetti {
    position: absolute;
    width: 12px;
    height: 20px;
    opacity: 1;
    animation: confettiFall 3s linear infinite;
    transform: rotate(45deg);
}

/* Confetti Color Variations */
.confetti:nth-child(7n) { background: red; }
.confetti:nth-child(7n+1) { background: yellow; }
.confetti:nth-child(7n+2) { background: blue; }
.confetti:nth-child(7n+3) { background: green; }
.confetti:nth-child(7n+4) { background: purple; }
.confetti:nth-child(7n+5) { background: silver; }
.confetti:nth-child(7n+6) { background: pink; }

/* Confetti Animation - Now Flies Up First */
@keyframes confettiFall {
    0% { transform: translateY(0) rotate(0deg); opacity: 1; }
    30% { transform: translateY(-50vh) rotate(360deg); opacity: 1; } 
    100% { transform: translateY(100vh) rotate(720deg); opacity: 0; }
}


/* Confetti Spread Across the Screen */
.confetti:nth-child(odd) {
    left: calc(10% + 80% * var(--random)); 
    animation-duration: calc(2s + 2s * var(--random)); 
}

.confetti:nth-child(even) {
    left: calc(20% + 70% * var(--random)); 
    animation-duration: calc(2s + 2s * var(--random));
}

/* .confetti:nth-child(odd) {
    left: calc(20% + 60% * random());
    animation-duration: calc(2.5s + random() * 2s);
}

.confetti:nth-child(even) {
    right: calc(20% + 60% * random());
    animation-duration: calc(2.5s + random() * 2s);
} */

/* Additional Randomization for Confetti Sizes */
.confetti:nth-child(10n) { width: 10px; height: 18px; }
.confetti:nth-child(15n) { width: 14px; height: 24px; }
.confetti:nth-child(20n) { width: 8px; height: 16px; }


/* Winner Pop-up */
.winner-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 320px;
    height: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border: 5px solid gold;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.5);
    animation: popUp 1s ease-in-out forwards;
}

/* Close Button for Pop-up */
.close-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none !important; /* Force remove background */
    color: black;
    border: none;
    font-size: 22px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    outline: none; /* Remove browser outline */
}

/* Hover effect: Enlarge "X" slightly */
.close-btn:hover {
    transform: scale(1.3);
    background: none !important; /* Ensure no grey background appears */
}

